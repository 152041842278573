@import '../../assets/global/main';

.MessagePage {
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__title {
    font-size: 2.4rem;
    color: $brand-saturated-purple;
    font-weight: 600;
  }

  &__description {
    color: $basic-headlings-color;
    line-height: 1.2;
    font-size: 1.2rem;
    max-width: 500px;
    display: inline-block;
    padding: 20px 10px;
    text-align: center;
  }
}
