@import './main.scss';

@font-face {
  font-family: 'NeueFrutiger';
  src: local(''), url('/fonts/NeueFrutiger/thin.woff2') format('woff2'),
    url('/fonts/NeueFrutiger/thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'NeueFrutiger';
  src: local(''), url('/fonts/NeueFrutiger/light.woff2') format('woff2'),
    url('/fonts/NeueFrutiger/light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'NeueFrutiger';
  src: local(''), url('/fonts/NeueFrutiger/regular.woff2') format('woff2'),
    url('/fonts/NeueFrutiger/regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'NeueFrutiger';
  src: local(''), url('/fonts/NeueFrutiger/bold.woff2') format('woff2'),
    url('/fonts/NeueFrutiger/bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

figure {
  margin: 0;
  padding: 0;
}

p {
  margin: 0 0 1rem 0;
  color: $basic-body-color;
  font-size: 16px;
}

ul,
ol {
  color: $basic-body-color;
  font-size: 16px;
  padding-left: 25px;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 3rem 0 1.38rem;
  font-weight: 400;
  line-height: 1.3;
  color: $basic-headlings-color;
}

h1 {
  margin-top: 0;
  font-size: 2.815rem;
}

h2 {
  font-size: 2.289rem;
}

h3 {
  font-size: 1.861rem;
}

h4 {
  font-size: 1.513rem;
}

h5 {
  font-size: 1.23rem;
}

small,
.text_small {
  font-size: 0.813rem;
}

img[alt$='>'] {
  padding: 25px;
  float: right;

  @include for-size-big {
    float: none;
    padding: 0;
    display: block;
    margin: 25px auto;
  }
}

img[alt$='<'] {
  padding: 25px;
  float: left;

  @include for-size-big {
    float: none;
    padding: 0;
    display: block;
    margin: 25px auto;
  }
}

img[alt$='><'] {
  padding: 25px;
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
  float: none !important;
}
